import { MenuIcon } from '@heroicons/react/outline'
import { useBooleanToggle, useClickOutside, useMediaQuery, useScrollLock } from '@mantine/hooks'
import { TOP_BANNER_SHOWROOM_LIST } from 'features/common/consts'
import { useTopBanner } from 'features/common/queries/useTopBanner'
import _ from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import QueryString from 'qs'
import { FC, useCallback, useEffect, useLayoutEffect } from 'react'
import { useDialog } from 'shared/hooks/useDialog'

import HeaderTopBanner from '../../features/common/components/HeaderTopBanner'
import { IProfile } from '../../stores/auth'
import Dropdown from './Dropdown'
import HeaderNav from './HeaderNav'
import MobileDropdown from './MobileDropdown'

interface IDHeaderProps {
  isSigned?: boolean
  signInUrl: string
  profile: IProfile | null
}

const Header: FC<IDHeaderProps> = ({ isSigned, signInUrl, profile }) => {
  const { data: topBannerData } = useTopBanner()
  const { isDialogOpened, onDialogOpenHandler, onDialogCloseHandler } = useDialog()

  const [isDropDownOpen, toggle] = useBooleanToggle(false)
  const [, setLockScroll] = useScrollLock()
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const router = useRouter()
  const state = QueryString.stringify({ state: location.pathname })
  const signInUrlWithState = `${signInUrl}&${state}`
  const goToMain = useCallback(() => void router.push('/').then(() => window.scrollTo(0, 0)), [
    router,
  ])
  const goToSignIn = useCallback(() => router.push(signInUrlWithState), [
    router,
    signInUrlWithState,
  ])

  const goToMyCourses = useCallback(
    () => router.push('/my/courses').then(() => window.scrollTo(0, 0)),
    [router],
  )
  const dropDownToggle = useCallback(() => {
    toggle()
  }, [toggle])

  const dropDownRef = useClickOutside<HTMLDivElement>(() => toggle(false), null, [
    document.getElementById('userIcon') as HTMLDivElement,
  ])

  useLayoutEffect(() => {
    const mobileResizeWindow = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    mobileResizeWindow()
    window.addEventListener('resize', mobileResizeWindow)

    if (isDropDownOpen && isMobile) {
      setLockScroll(true)
    } else {
      setLockScroll(false)
    }

    return () => {
      window.removeEventListener('resize', mobileResizeWindow)
    }
  }, [isDropDownOpen, isMobile])

  const isPlayerPage = _.isEqual(
    router.pathname,
    '/courses/technology/[id]/[chapterId]/[resourceId]',
  )

  const isPeerGroupPage = _.includes(router.pathname, 'pg-dashboard')
  const isHeaderTopBanner = [...TOP_BANNER_SHOWROOM_LIST].some(page => page === router.asPath)

  return (
    <>
      {isHeaderTopBanner && !_.isEmpty(topBannerData) && (
        <HeaderTopBanner
          bannerData={topBannerData}
          isDialogOpened={isDialogOpened}
          onDialogOpenHandler={onDialogOpenHandler}
          onDialogCloseHandler={onDialogCloseHandler}
        />
      )}
      <header
        className="sticky left-0 top-0 z-200 flex h-16 w-full border-b bg-white"
        style={{ minWidth: 360 }}>
        <div
          className={`${
            isPlayerPage || isPeerGroupPage ? 'max-w-screen-3xl' : 'max-w-7xl'
          } relative z-75 mx-auto box-border flex-1 self-center`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                className="flex cursor-pointer items-center justify-center px-4 text-center xl:px-6"
                onClick={e => {
                  e.preventDefault()
                  goToMain()
                }}>
                <div className="relative h-4 w-[144px] xl:h-4.5 xl:w-[162px]">
                  <Image
                    src="/img/likelion_kor_logo.svg"
                    alt="likelion"
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </button>
              <HeaderNav />
            </div>
            <div className="flex shrink-0 items-center pr-2">
              {isMobile ? (
                <>
                  {!isSigned && (
                    <button onClick={goToSignIn}>
                      <span className="relative mr-2 rounded-full border px-4 py-2 text-sm font-semibold">
                        로그인
                      </span>
                    </button>
                  )}
                  <div className="flex cursor-pointer items-center justify-center lg:relative">
                    <div onClick={dropDownToggle}>
                      <MenuIcon width={40} className="p-2" />
                    </div>
                    {isDropDownOpen && (
                      <MobileDropdown
                        profile={profile}
                        isSigned={isSigned}
                        signInUrl={signInUrl}
                        ondropDownToggleHandler={dropDownToggle}
                      />
                    )}
                  </div>
                </>
              ) : isSigned ? (
                <div className="mr-4 inline-flex items-center space-x-6">
                  <p
                    className="hidden cursor-pointer text-tiny font-semibold md:block "
                    onClick={goToMyCourses}>
                    내 강의실
                  </p>
                  <div id="userIcon" className="h-9 w-9 lg:relative">
                    <div onClick={dropDownToggle} className="relative h-9 w-9">
                      {profile && (
                        <img
                          src={
                            !_.isEmpty(profile?.profileImg)
                              ? (profile.profileImg as string)
                              : '/svg/icon_user.svg'
                          }
                          className="h-full w-full cursor-pointer overflow-hidden rounded-full object-cover"
                          alt="회원아이콘"
                        />
                      )}
                    </div>
                    {isDropDownOpen && <Dropdown profile={profile} ref={dropDownRef} />}
                  </div>
                </div>
              ) : (
                <button onClick={goToSignIn} className="flex items-center px-4 py-5 text-gray-500">
                  <span className="relative text-base  font-semibold lg:border-none">로그인</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
